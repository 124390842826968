
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  filmOutline,
  compassOutline,
  searchOutline,
  menuOutline
} from "ionicons/icons";
import { upper } from "@/helpers/upper";
import { useRouter } from "vue-router";
import { ref, inject, onMounted, watch } from "vue";
import SweetScroll from "sweet-scroll";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup: () => {
    const router = useRouter();

    const ionRouter: any = inject("navManager");

    const tabs = [
      {
        tab: "home",
        icon: filmOutline,
        prev: null
      },
      {
        tab: "explore",
        icon: compassOutline,
        prev: null
      },
      {
        tab: "search",
        icon: searchOutline,
        prev: null
      },
      {
        tab: "menu",
        icon: menuOutline,
        prev: null
      }
    ];

    const currentRoute = ref(
      tabs.find(_ => _.tab === router.currentRoute.value.name)?.tab || "home"
    );

    watch(() => router.currentRoute.value, () => {
      if (router.currentRoute.value.name === 'auth' || router.currentRoute.value.name === 'login'  || router.currentRoute.value.name === 'register') {
        currentRoute.value = "home"
      }
    }, { immediate: true });

    const onClick = (tab: any) => {
      if (router.currentRoute.value.name === tab.tab) {
        // click on root tab
        new SweetScroll(
          {
            duration: 1000,
            easing: "easeOutExpo"
          },
          `ion-content#${tab.tab}-content`
        ).toTop(0);
      }
       else {
        if (currentRoute.value === tab.tab) {
          // click on nested tab
          ionRouter.navigate({
            routerDirection: "back",
            routerLink: "/" + tab.tab
          });
        } else {
          // click another tab
          (tabs.find(_ => _.tab === currentRoute.value) as any).prev =
            router.currentRoute.value.path;
          const newLink =
            (tabs.find(_ => _.tab === tab.tab) as any)?.prev ||
            `/${tab.tab}`;
          ionRouter.navigate({
            routerDirection: "none",
            routerLink: newLink
          });
        }
      }
      currentRoute.value = tab.tab;
    };

    return {
      router,
      onClick,
      tabs,
      upper,
      currentRoute,
      onMounted
    };
  }
};
