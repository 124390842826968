
import {
  IonPage,
  IonContent,
  IonFooter,
  IonToolbar,
  IonButton,
  IonGrid,
  IonInput,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonCheckbox,
  IonCol,
  IonModal,
  IonText,
  IonRow,
  IonSpinner,
  IonNote,
  IonSlides,
  IonSlide,
  onIonViewWillEnter,
  onIonViewDidEnter,
  toastController,
} from "@ionic/vue";
import { defineComponent, ref, inject, computed, nextTick, watch } from "vue";

import eventBus from '@/helpers/event-bus';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import caph from '@/helpers/caph';

import { auth, subscriptions } from "@/services/api";

import { InAppPurchase as purchase } from '@ionic-native/in-app-purchase';

import { eyeOutline, eyeOffOutline, chevronBackOutline, alertCircleOutline } from 'ionicons/icons';
import NativeLink from "@/components/shared/NativeLink.vue";
import analyticsManager from '../../customPlugins/AppAnalytics';

import { isWebPlatform } from "@/helpers/orientation";

const PRODUCT_ID = "1_month";

export default defineComponent({
  name: "Auth",
  components: {
    IonContent,
    IonPage,
    IonFooter,
    IonModal,
    IonToolbar,
    IonList,
    IonGrid,
    IonSpinner,
    IonIcon,
    NativeLink,
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonCol,
    IonSlides,
    IonSlide,
    IonRow,
    IonNote,
    IonText,
    IonButton,
  },
  ...caph,
  props: {
    isOnLogin: {
      type: Boolean,
    },
    isOnSubscribe: {
      type: Boolean,
    },
    isShowLogin: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();

    const ionRouter: any = inject("navManager");
    const isWeb = ref(isWebPlatform());
    const IsTestFlightEnabled = ref(store.getters['cache/IsTestFlightEnabled']);
    const cred = ref({
      email: "",
      password: "",
      passwordConfirm: "",
    });

    const emailLogin = ref(null);
    const emailSubscribe = ref(null);
    const passSubscribe = ref(null);
    const passConfirmSubscribe = ref(null);

    const refNameToRef: any = (refName) => {
      let ref:any;
      switch (refName) {
        case 'email-login': 
          ref = emailLogin.value;
          break;
        case 'email-subscribe':
          ref = emailSubscribe.value
          break;
        case 'confirm-pass-subscribe':
          ref = passConfirmSubscribe.value
          break;
        default:
          ref = passSubscribe.value;
          break;
      }
      return ref;
    }

    const validationsPassword = [
      // {
      //   title: 'At least 8 characters',
      //   predicate: (input) => input.length >= 8,
      // },
      // {
      //   title: 'Consists of numbers and letters',
      //   predicate: (input) => /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input),
      // },
      // {
      //   title: 'Contains upper and lower case letters',
      //   predicate: (input) => /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input),
      // },
      {
        title: 'Your password length should be 8 or more, with lowercase, uppercase, and symbols',
        predicate: (input) => input.length >= 8 && /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input) && /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input)
      },
      {
        title: 'Passwords must match',
        predicate: (input) => input === cred.value.passwordConfirm,
      }
    ];

    const validateEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value?.toLowerCase(),
  );
    const validateEmailInput = (ev, refName) => {
      const value = ev.target.value;

      refNameToRef(refName).$el.classList.remove('ion-invalid');

      if (value === '') return;

      validateEmail(value)
        ? refNameToRef(refName).$el.classList.add('ion-valid')
        : refNameToRef(refName).$el.classList.add('ion-invalid');
    };
    const markTouched = (refName) => {
      refNameToRef(refName).$el.classList.add('ion-touched')
    };
    const validatePass = (pass) => {
      return validationsPassword.every(_ => _.predicate(pass));
    };
    const validatePassInput = (ev, refName) => {
      const value = ev.target.value;

      refNameToRef(refName).$el.classList.remove('ion-valid');
      refNameToRef(refName).$el.classList.remove('ion-invalid');

      if (value === '') return;

      validatePass(value)
        ? refNameToRef(refName).$el.classList.add('ion-valid')
        : refNameToRef(refName).$el.classList.add('ion-invalid');
    };
    const validatePassMessage = () => {
      return validationsPassword.find(_ => !_.predicate(cred.value.password))?.title || '';
    }

    const isChecked = ref(false);

    const login = () => {
      context.emit('login', true);
    }

    const subscribe = () => {
      context.emit('subscribe', true);
    }
    const close = () => {
      context.emit('close');
    }
    
    onIonViewWillEnter(async () => {
      document.getElementById('ion-app')!.classList.add('with-desktop-header');
      const t = await purchase.getProducts([PRODUCT_ID]);
      store.dispatch('cache/setSubscribePrice', (t as any[]).find((product) => product.productId === PRODUCT_ID).price)
    })

    onIonViewDidEnter(async () => {
      if (router.currentRoute.value.query.delete) {
        const toast = await toastController.create({
          message: "Thank you. Your account has been sent for review, and will be processed in 72 hours.",
          duration: 2000,
          color: 'warning',
          position: 'middle'
        });
        return toast.present();
      }
    })

    const isLoading = ref(false);
    const isPasswordShow = ref(false);
    const isPasswordFocus = ref(false);

    const loginUser = async () => {
      if (isDisabled.value) {
        return;
      }
      isLoading.value = true;
      await auth
        .login(cred.value)
        .then(async res => {
          if (res.data) {
            const data = res.data;
            if (auth) {
              await setClientInfo(data);
              cred.value = {
                email: "",
                password: "",
                passwordConfirm: "",
              };
              close();
              ionRouter.navigate({
                routerDirection: "forward",
                routerLink: '/'
              });
              nextTick(() => {
                eventBus().emitter.emit('route-update');
              });
            }
          }
        })
        .catch(async err => {
          console.error(err);
          const toast = await toastController.create({
            message: "Incorrect username or password",
            duration: 2000,
            color: "danger"
          });
          return toast.present();
        });
      isLoading.value = false;
    };

    const onCheckedChange = (event) => {
      isChecked.value = event.detail.checked;
    }

    const onFocus = () => {
      isPasswordFocus.value = true;
    }

    const offFocus = () => {
      isPasswordFocus.value = false;
    }

    const togglePasswordShow = () => {
      isPasswordShow.value = !isPasswordShow.value;
    }


    const isDisabled = computed(() => {
      return props.isOnLogin ? (!cred.value.password || !validateEmail(cred.value.email))
      : (!validatePass(cred.value.password) || !validateEmail(cred.value.email) || !isChecked.value || cred.value.password !== cred.value.passwordConfirm) 
    })

    const goToPass = (postfix) => {
      (document.getElementById('input-password-' + postfix) as any).setFocus();
    }

    watch(() => props.isOnLogin, async () => {
      await nextTick();
      (document.getElementById('input-email-login') as any)?.setFocus();
    }, {
      flush: 'post'
    });

    watch(() => props.isOnSubscribe, async () => {
      await nextTick();
      (document.getElementById('input-email-subscribe') as any)?.setFocus();
    }, {
      flush: 'post'
    });

    onIonViewWillEnter(() => {
      document.getElementById('ion-app')!.classList.add('with-desktop-header');
    })


    const setClientInfo = (payload: any) => store.dispatch("auth/setClientInfo", payload);
    const setToken = (token: string) => store.dispatch("auth/setToken", token);


    const signUpAndSubscribe = async () => {
      isLoading.value = true;

      try {
        const subscriptionInfo = await purchase.buy(PRODUCT_ID)

        try {
          const validSubsription = await subscriptions.consumeUserSubscription({ subscriptionInfo, user: { ...cred.value } })
          const price = store.state.subscribeInfo.price
          const info = { key: 'af_subscribe', value: { 'af_revenue': price.replace(/[^\d.-]/g, ''), 'af_currency': price.replace(/[0-9]/g, '')}}
          await analyticsManager.sendAnalytics(info)

          console.log(validSubsription)

          //TODO: add password hash
          if (validSubsription.subscription.receipt) {
            //signup user
            const { data: { user } } = await auth.signup(cred.value)
            if (user) {
              //sync woocommerce subscription
              const woocommerceSubscription = await subscriptions.processUserSubscription({ user, subscription: validSubsription })
              console.log('woocommerceSubscription', woocommerceSubscription)
              //login user
              if (isDisabled.value) {
                return;
              }
              await auth
                .login(cred.value)
                .then(async ({ data }) => {
                  if (data) {
                    if (auth) {
                      await setClientInfo(data);
                      close();
                      cred.value = {
                        email: "",
                        password: "",
                        passwordConfirm: "",
                      };
                      ionRouter.navigate({
                        routerDirection: "forward",
                        routerLink: '/home'
                      });
                      nextTick(() => {
                        eventBus().emitter.emit('route-update');
                      });
                    }
                  }
                })
              isLoading.value = false
            }
          }
        } catch (error) {
          console.log('Processing error', error)
          const toast = await toastController.create({
            message: "Try again",
            duration: 2000,
            color: "danger"
          });
          isLoading.value = false
          return toast.present();
        }
      } catch (error) {
        console.log('Consuming error', error)
        const toast = await toastController.create({
          message: "Try again",
          duration: 2000,
          color: "danger"
        });
        isLoading.value = false
        return toast.present();
      }
      //TODO: нужно сохранять успешные транзакции + креды в таблицу
    };

    return {
      router,
      isChecked,
      isLoading,
      close,
      login,
      subscribe,
      isWeb,
      cred,
      IsTestFlightEnabled,
      onCheckedChange,
      signUpAndSubscribe,
      goToPass,
      isDisabled,
      chevronBackOutline,
      eyeOutline,
      eyeOffOutline,
      isPasswordShow,
      alertCircleOutline,
      togglePasswordShow,
      isPasswordFocus,
      onFocus,
      loginUser,
      validationsPassword,
      offFocus,
      validateEmailInput,
      validatePassInput,
      validatePassMessage,
      validateEmail,
      validatePass,
      markTouched,
      emailLogin,
      emailSubscribe,
      passSubscribe,
      passConfirmSubscribe,
      store,
    };
  }
});
