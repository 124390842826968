
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { isWebPlatform, lock } from "./helpers/orientation";

import AuthForm from "@/components/shared/AuthForm.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { throttle } from "@/helpers/throttle";

import { defaultClient } from "@/main";

import updateTimestamp from "./graphql/updateTimestamp.mutation.graphql";
import createTimestamp from "./graphql/createTimestamp.mutation.graphql";

import DesktopHeader from "./components/shared/DesktopHeader.vue";

import eventBus from "@/helpers/event-bus";

import { SECONDS_TRIAL } from "@/helpers/trial";

import { PushNotifications } from '@capacitor/push-notifications';

const addListeners = async () => {
  console.log('1');
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);
  });
  console.log('2');
  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
    console.log('ERROR 2');
  });
  console.log('3');
  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.info('Push notification received: ', notification);
    console.log('ERROR 3');
  });
  console.log('4');
  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
    console.log('ERROR 4');
  });
};


export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    DesktopHeader,
    AuthForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const routeName = computed(() => {
      return router.currentRoute.value.name;
    })

    const routerKey = ref(0);
    const isPopupSubscribeOpen = ref(false);

    const onRouteUpdate = () => {
      routerKey.value++;
    };
    const onPopupShown = (payload) => {
      isPopupSubscribeOpen.value = payload;
    }

    eventBus().emitter.on("route-update", onRouteUpdate);
    eventBus().emitter.on('popup-subscribe-shown', onPopupShown);

    const isWeb = ref(isWebPlatform());

    onBeforeMount(async () => {
      try {
        await addListeners();
      } 
      catch (e){
         console.error(e);
      }
      await lock();
      if (router.currentRoute.value.hash === "#nav") {
        router.replace({ hash: "" });
        store.dispatch("cache/setNavigationShown", true);
      }
    });



    onMounted(async () => {
      const video = document.getElementById("video") as any;

      video!.addEventListener("loadedmetadata", () => {
        const ts = +video.getAttribute("ts") || 0;
        video!.currentTime = ts;
      });

      const watchHandler = async () => {
        const currentTimePerSecond = video.currentTime;

        if (store.state.auth.isFree) {
          const timeWatched = store.state.cache.freeSeconds;
          if (timeWatched >= SECONDS_TRIAL) {
            router.push("/home?endTime=1");
          } else {
            store.dispatch("cache/setFreeSeconds", timeWatched + 3);
          }

          if (timeWatched >= (SECONDS_TRIAL / 2) && store.state.cache.halfFreeTimeout !== 'DONE') {
            store.dispatch('cache/setHalfFreeTimeout', 'ACHIEVED');
          }
        }

        const assetId = video.getAttribute("asset-id");
        const tsId = video.getAttribute("ts-id");

        if (!assetId || !assetId.length) {
          return;
        }

        if (
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        ) {
          try {
            await defaultClient.mutate({
              mutation: updateTimestamp,
              variables: {
                ts: {
                  id: tsId,
                  assetId: assetId,
                  timestamp: parseInt(currentTimePerSecond),
                  userId: String(store.state.auth.userId)
                }
              }
            });
          } catch (e) {
            console.log(e);
            const response = await defaultClient.mutate({
              mutation: createTimestamp,
              variables: {
                ts: {
                  assetId: assetId,
                  published: 1,
                  timestamp: parseInt(currentTimePerSecond),
                  userId: String(store.state.auth.userId)
                }
              }
            });
            video.setAttribute("ts-id", response.data.createTimestamp.id);
          }
        }
      };
      video!.addEventListener("timeupdate", throttle(watchHandler, 3000));
      video!.addEventListener("seeked", watchHandler);
    });

    if (store.state.cache.timer.ts) {
      const interval = setInterval(() => {
        const timer = store.state.cache.timer.ts;
        const isAvailable =
          !timer ||
          timer === "false" ||
          timer === "undefined" ||
          timer - new Date().getTime() > 0;
        if (!isAvailable) {
          clearInterval(interval);
          router.push("/lock");
        }
      }, 10000);
    }

    return {
      store,
      onRouteUpdate,
      routerKey,
      router,
      isPopupSubscribeOpen,
      onPopupShown,
      routeName,
    };
  }
});
