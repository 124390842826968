
import { IonIcon } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { stopwatchOutline } from "ionicons/icons";

import CircleProgress from 'vue-svg-circle-progress';

import { useStore } from "vuex";

import { secondsToHms } from "@/helpers/secondToHms";

export default defineComponent({
  name: "Timer",
  components: { IonIcon, CircleProgress },
  setup() {
    const store = useStore();
    const totalSteps = computed(() => store.state.cache.timer.timeout);
    const now = ref(new Date().getTime());
    const interval = setInterval(() => {
      now.value = new Date().getTime();
    }, 1000);
    const completedSteps = computed(() => (store.state.cache.timer.ts - now.value) / 10);
    const timeLeft = computed(() => secondsToHms(+((store.state.cache.timer.ts - now.value) / 1000).toFixed(0)));
    return { 
      stopwatchOutline,
      completedSteps,
      totalSteps,
      now,
      timeLeft,
      percentage: computed(() => completedSteps.value/totalSteps.value),
      interval,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  ionViewWillLeave() {
    clearInterval(this.interval);
  }
});
