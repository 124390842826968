
import { defineComponent, inject, ref } from "vue";
import { useStore } from "vuex";
import {
  IonIcon,
  toastController,
} from "@ionic/vue";

import { upper } from "@/helpers/upper";

import { defaultClient } from "@/main";
import getFlags from '@/graphql/getFlags.query.graphql';

import { InAppPurchase as purchase } from '@ionic-native/in-app-purchase';

import {
  mailOutline,
  playCircleOutline,
  timeOutline,
  settingsOutline,
  paperPlaneOutline,
  logOutOutline,
  personOutline
} from "ionicons/icons";
import { isWebPlatform } from "@/helpers/orientation";

export default defineComponent({
  name: "Menu",
  components: {
    IonIcon,
  },
  setup() {
    const ionRouter = inject("navManager") as any;
    const store = useStore();
    const email = store?.state?.auth?.email;
    const username = store?.state?.auth?.username;
    const subscription = upper(store?.state?.auth?.subscriptionInfo);
    const isFree = store.state.auth.isFree;
    const IsTestFlightEnabled = ref(store.getters['cache/IsTestFlightEnabled']);

    const logout = async (isDelete = false) => {
      if (isDelete) {
        try {
          const flags = await defaultClient.query({
            query: getFlags,
          });
        } catch (e) {
          console.log('success delete'); // it's joke
        }
      }
      ionRouter.navigate({
        routerDirection: "none",
        routerLink: isDelete ? '/auth?delete=1' : '/auth',
      });
      store.commit("auth/logout");
    };

    const isWeb = ref(isWebPlatform());

    const restorePurchase = async () => {
      try {
        const p = await purchase.restorePurchases();
        console.log(p);
      } catch (e) {
        console.error(e);
        const toast = await toastController.create({
          message: "Something went wrong",
          duration: 2000,
          color: "danger"
        });
        return toast.present();
      }
    }

    return {
      store,
      isWeb,
      email,
      username,
      logout,
      restorePurchase,
      mailOutline,
      playCircleOutline,
      subscription,
      timeOutline,
      personOutline,
      settingsOutline,
      paperPlaneOutline,
      logOutOutline,
      IsTestFlightEnabled,
      isFree,
    };
  }
});
