import { upper } from "@/helpers/upper";
// TODO Fix types later
// @ts-nocheck
const state = {
    isLoading: false,
    token: '',
    refreshToken: '',
    email: '',
    userId: '',
    username: null,
    partnerId: undefined,
    continueWatchingAssets: [],
    subscriptionInfo: '',
    subscriptionId: null,
    isFree: false,
}

const actions = {
    setToken({ commit }, token) {
        commit('setToken', token);
    },
    setTokens({ commit }, tokens) {
        commit('setTokens', tokens);
    },
    removeToken({ commit }) {
        commit('setToken', '');
    },
    setEmail({ commit }, email) {
        commit('setEmail', email);
    },
    setUsername({ commit }, username) {
        commit('setUsername', username);
    },
    setPartnerId({ commit }, partnerId) {
        commit('setPartnerId', partnerId);
    },
    setClientInfo({ commit }, payload) {
        commit('setClientInfo', payload);
    },
    setClientInfoFree({ commit }, payload) {
        commit('setClientInfoFree', payload);
    },
    removeEmail({ commit }) {
        commit('setEmail', '');
    },
    setUserId({ commit }, userId) {
        commit('setUserId', userId);
    },
    removeUserId({ commit }) {
        commit('setUserId', '');
    },
    setContinueWatchingAssets({ commit }, continueWatchingAssets) {
        commit('setContinueWatchingAssets', continueWatchingAssets);
    },
    logout({ commit }) {
        commit('logout');
    },
}
const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setTokens(state, tokens) {
        state.token = tokens.token;
        state.refreshToken = tokens.refreshToken;
    },
    setPartnerId(state, partnerId) {
        state.partnerId = partnerId;
    },
    setEmail(state, email) {
        state.email = email
    },
    setClientInfo(state, payload) {
        state.username = payload.username;
        state.partnerId = payload.partnerId;
        state.token = payload.token;
        state.email = payload.email;
        state.userId = payload.userId;
        state.subscriptionId = payload.subscription?.id;
        state.subscriptionInfo = `${upper(payload.subscription?.status)} by ${new Date(payload.subscription?.date_created_gmt).toLocaleDateString('en-US')}`;
        state.refreshToken = payload.refreshToken;
        state.isFree = false;
    },
    setClientInfoFree(state, payload) {
        state.token = payload.token;
        state.userId = payload.userId;
        state.isFree = true;
    },
    setUserId(state, userId) {
        state.userId = userId
    },
    setContinueWatchingAssets(state, continueWatchingAssets) {
        state.continueWatchingAssets = continueWatchingAssets
    },
    setUsername(state, username) {
        state.username = username;
    },
    logout(state) {
        state.token = '';
        state.username = null;
        state.partnerId = undefined;
        state.userId = '';
        state.email = '';
        state.isFree = false;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
