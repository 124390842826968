
import { watch, defineComponent, ref, inject, computed } from 'vue';
import {
  IonIcon,
  IonRange,
  IonSearchbar,
  IonBackButton,
} from "@ionic/vue";

import { useStore } from "vuex";

import Timer from "./Timer.vue";
import FreeTimer from './FreeTimer.vue'

import { searchOutline, menuOutline, closeOutline } from "ionicons/icons";
import MenuInfo from './MenuInfo.vue';
import { useRouter } from 'vue-router';

import eventBus from '@/helpers/event-bus';

export default defineComponent({
  components: {
    IonIcon,
    IonRange,
    Timer,
    FreeTimer,
    MenuInfo,
    IonSearchbar,
    IonBackButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const ionRouter: any = inject("navManager");

    const isSearchShow = ref(false);
    const isSearchNavigated = ref(false);
    const isMenuShow = ref(false);
    const value = ref(0);
    const searchText = ref(router.currentRoute.value.query.search);
    const isPreventHeaderShow = ref(false);

    const isShowDesktopHeader = computed(() => {
      return !['auth', 'login', 'register'].includes(router.currentRoute.value.name?.toString() || '') && !isPreventHeaderShow.value;
    })

    const toggleDesktopHeader = (flag) => {
      isPreventHeaderShow.value = flag;
    }

    watch(() => router.currentRoute.value, (val, oldVal) => {
      searchText.value = router.currentRoute.value.query.search;
      if (val.name !== oldVal.name) {
        isMenuShow.value = false;
        if (val.name !== 'search') {
          isSearchShow.value = false;
        }
      }
    });
    const onTimerClick = () => {
      eventBus().emitter.emit('timer-settings', true);
    }

    eventBus().emitter.on('timer-settings', toggleDesktopHeader);

    const onChange = (e: any) => {
      value.value = e.detail.value;
    }
    
    const onSearchTextChange = (e: any) => {
      searchText.value = e.target.value;
    }

    const onSearchEnter = () => {
      eventBus().emitter.emit('search', searchText.value);
    } 

    const onSearchClick = () => {
      router.push({ name: 'search' });
      isSearchNavigated.value = true;
      isSearchShow.value = true;
    };

    const onSearchClear = () => {
      if (isSearchNavigated.value) {
        router.go(-1);
        isSearchNavigated.value = false;
      }
      isSearchShow.value = false;
    }

    const onLogoClick = () => {
      ionRouter.navigate({
        routerDirection: "back",
        routerLink: { name: 'home' },
      });
      isSearchShow.value = false;
    }

    return {
      searchOutline,
      menuOutline,
      closeOutline,
      isMenuShow,
      onTimerClick,
      isPreventHeaderShow,
      onChange,
      value,
      onSearchClick,
      onSearchTextChange,
      onSearchClear,
      isSearchShow,
      searchText,
      onSearchEnter,
      onLogoClick,
      router,
      isShowDesktopHeader,
    };
  },
});
