
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { SECONDS_TRIAL } from '@/helpers/trial';

const stringify = (n) => {
  if (String(n).length === 1) {
    return `0${n}`;
  } else {
    return String(n);
  }
}

export default defineComponent({
  name: "FreeTimer",
  setup(_props, context) {
    const store = useStore();
    const timeWatched = computed(() => store.state.cache.freeSeconds);
    const isFree = computed(() => store.state.auth.isFree);
    const time = computed(() => {
      if (timeWatched.value <= SECONDS_TRIAL) {
        const totalSeconds = SECONDS_TRIAL - timeWatched.value;
        const minutes = stringify(Math.floor(totalSeconds / 60));
        const seconds = stringify(totalSeconds % 60);
        return `${minutes}:${seconds}`;
      } else {
        return null;
      }
    })
    const onClick = () => {
      context.emit('open-modal');
    }

    return {
      isFree,
      time,
      onClick,
      timeWatched,
      SECONDS_TRIAL,
    }
  },
});
